<template>
  <div class="col-12 " style="display: flex; justify-content: center;align-items: center;">
    <div :class="$style.card">
      <div class="d-flex flex-column align-items-center justify-content-center my-5">
        <h1 style="color: #800152;font-size: clamp(1.1875rem, 0.4365rem + 3.2044vw, 3rem);">
          <strong>Selamat datang di ANITA</strong>
        </h1>
        <p style="color: #800152;font-size: clamp(0.9375rem, 0.4972rem + 1.8785vw, 2rem);">
          (Patient Navigator Assistance)
        </p>
      </div>
      <!-- @finish="handleFinish"
      @finishFailed="handleFinishFailed" -->
      <a-form layout="vertical" class="mb-4 mt-3">
        <a-form-item name="namaLengkap" v-bind="validateInfos.namaLengkap">
          <label
            for="exampleFormControlInput1"
            class="form-label font-custom"
            style="font-size: 15px;"
            >Nama Lengkap <span style="color: red;">*</span></label
          >
          <a-input
            v-model:value="registerForm.namaLengkap"
            placeholder="Masukkan nama"
            style="height: 40px; font-size: 18px; padding-left: 15px;"
          />
        </a-form-item>

        <a-form-item name="nomorHandphone" v-bind="validateInfos.nomorHandphone">
          <label
            for="exampleFormControlInput1"
            class="form-label font-custom"
            style="font-size: 15px;"
            >Nomor Handphone <span style="color: red;">*</span></label
          >
          <a-input
            v-model:value="registerForm.nomorHandphone"
            placeholder="Masukkan nomor handphone"
            style="height: 40px; font-size: 18px; padding-left: 15px;"
          />
        </a-form-item>
        <a-form-item name="email" v-bind="validateInfos.email">
          <label
            for="exampleFormControlInput1"
            class="form-label font-custom"
            style="font-size: 15px;"
            >Email</label
          >
          <a-input
            type="email"
            v-model:value="registerForm.email"
            placeholder="Masukkan email"
            style="height: 40px; font-size: 18px; padding-left: 15px;"
          />
        </a-form-item>
        <a-form-item name="ktp" v-bind="validateInfos.ktp">
          <label
            for="exampleFormControlInput1"
            class="form-label font-custom"
            style="font-size: 15px;"
            >Unggah Foto KTP</label
          >
          <br />
          <a-upload
            class="upload-section"
            :file-list="fileList"
            list-type="picture-card"
            @change="handleChange"
            :max-count="1"
            :before-upload="() => false"
            @preview="handlePreview"
          >
            <div
              v-if="!fileList.length"
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <camera-outlined></camera-outlined>
              <div>Unggah Foto</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item name="resep" v-bind="validateInfos.resep">
          <label
            for="exampleFormControlInput1"
            class="form-label font-custom"
            style="font-size: 15px;"
            >Unggah Foto Resep</label
          >
          <br />
          <a-upload
            class="upload-section"
            list-type="picture-card"
            :file-list="fileListResep"
            @change="handleChangeResep"
            @preview="handlePreview"
            :before-upload="() => false"
          >
            <div
              v-if="!fileListResep.length"
              style="width:"
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <camera-outlined></camera-outlined>
              <div>Unggah Foto</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" centered>
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
        <div class="row">
          <!-- <div class="col" v-if="steps > 0">
            <a-button type="primary" class="btn text-center w-100" :loading="loading"
              style="height: 50px; font-size: 18px;" @click.prevent="handleBack">
              <strong>Kembali</strong>
            </a-button>
          </div> -->
          <div class="col">
            <a-button
              class="btn col text-center w-100 text-white"
              :loading="loading"
              style="height: 50px; font-size: 18px; background: linear-gradient(to left, #C76B1E 0%, #D58213 38%, #EDA702 100%);"
              @click.prevent="handleFinish"
            >
              <strong>Kirim</strong>
            </a-button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 text-center">
            <p style="color: #800152;font-size: clamp(0.875rem, 0.7973rem + 0.3315vw, 1.0625rem);">
              <strong>
                Terima kasih telah submit data diri Anda, kami akan menghubungi Anda segera
              </strong>
            </p>
          </div>
        </div>
        <a-modal
          v-model:visible="visible"
          width="600px"
          centered
          footer=""
          :closable="false"
          style="top: 40px; border-radius: 32px;"
        >
          <div class="p-4" style="height: 500px;overflow-y: auto;">
            <div class="body">
              <div style="line-height: 1.5;">
                <p style="font-size: 12px;color: black;">
                  Terima kasih telah menghubungi
                  <span style="color: #800152;"
                    ><strong>ANITA (Patient Navigator Assistance)</strong></span
                  >
                  yang merupakan layanan navigator pasien yang diinisiasi Yayasan Kanker Indonesia
                  dan dikelola oleh layanan jasa APL SHS.
                </p>
                <p style="font-size: 12px; color: black;">
                  Dengan membaca pesan ini sampai selesai, Anda akan terhubung dengan
                  agen/konsultan  kami dan Anda juga
                  <strong
                    >setuju bahwa kami dapat  mengumpulkan dan menyimpan informasi minimal yang
                    dapat diidentifikasi, yang Anda berikan secara sukarela, untuk tujuan menanggapi
                    pertanyaan Anda, </strong
                  >tujuan administrative program ini dan mematuhi kewajiban hukum, seperti namun
                  tidak terbatas pada informasi kontak Anda dan atau kondisi terkait kejadian tidak
                  diinginkan, sesuai dengan
                  <strong
                    >Undang-Undang No. 27 tahun 2022 tentang Perlindungan Data Pribadi serta
                    kebijakan dan prosedur perlindungan Data Pribadi kami</strong
                  >. Akses ke informasi pribadi Anda terbatas hanya untuk personel kami yang
                  memiliki kebutuhan sah untuk melihatnya. Anda memiliki hak untuk mengakses
                  informasi pribadi Anda dan hak untuk meminta koreksi dan/ atau membatalkan data
                  Pribadi Anda atas ketidakakuratan informasi apa pun. Anda dapat meminta informasi
                  kontak petugas perlindungan data yang ditunjuk oleh program ini, dan / atau
                  Pemberitahuan Privasi lengkap kami, dari karyawan petugas yang berbicara dengan
                  Anda atau memintanya untuk dikirimkan kepada Anda melalui email.
                  <br />
                  <a-checkbox v-model:checked="checkedConsent" class="font-custom mt-2">
                    Saya Menyetujui Syarat dan Ketentuan yang berlaku</a-checkbox
                  >
                </p>
                <div class="d-flex">
                  <a-button
                    class="btn text-center w-100 mt-2 mr-3"
                    :loading="loading"
                    style="height: 48px; font-size: 18px;"
                    @click.prevent="closeModal"
                  >
                    Batalkan
                  </a-button>
                  <a-button
                    type="primary"
                    class="btn text-center w-100 mt-2"
                    style="height: 48px; font-size: 18px;"
                    :disabled="!checkedConsent"
                    @click.prevent="handleSubmit"
                  >
                    Lanjutkan
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-modal>
      </a-form>
    </div>
  </div>
</template>
<script>
import { computed, reactive, ref, onMounted, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
import cookies from 'vue-cookies'
import { Form } from 'ant-design-vue'
import apiClient from '@/services/axios'
import { CameraOutlined } from '@ant-design/icons-vue'
const useForm = Form.useForm
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'ThLogin',
  components: {
    CameraOutlined,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const settings = computed(() => store.getters.settings)
    const loading = computed(() => store.getters['user/user'].loading)
    const checked = ref(false)
    const checkedConsent = ref(false)
    const isRegister = ref(false)
    const steps = ref(2)
    // const cookies = ref(null)
    const rules = reactive({
      namaLengkap: [
        {
          required: true,
          message: 'Tolong masukkan Nama!',
        },
      ],
      gender: [
        {
          required: true,
          message: 'Tolong pilih Jenis Kelamin!',
        },
      ],
      tempatLahir: [
        {
          required: true,
          message: 'Tolong masukkan Tempat Lahir!',
        },
      ],
      ttl: [
        {
          required: true,
          message: 'Tolong masukkan Tanggal Lahir!',
        },
      ],
      nik: [
        {
          required: true,
          message: 'Tolong masukkan NIK!',
        },
      ],
      idTest: [
        {
          required: true,
          message: 'Tolong masukkan Id Test!',
        },
      ],
      jenisAsuransi: [
        {
          required: true,
          message: 'Tolong pilih Jenis Asuransi!',
        },
      ],
      namaAsuransi: [
        {
          required: true,
          message: 'Tolong masukkan Nama Asuransi',
        },
      ],
      nomorAsuransi: [
        {
          required: true,
          message: 'Tolong masukkan Nomor Asuransi',
        },
      ],
      email: [
        {
          type: 'email',
          message: 'Format email tidak valid!',
        },
        // {
        //   required: true,
        //   message: 'Tolong masukkan Email!',
        // },
      ],

      nomorHandphone: [
        {
          required: true,
          message: 'Tolong masukkan Nomor Handphone!',
        },
        {
          pattern: /^[0-9]*$/,
          message: 'Hanya angka yang diperbolehkan!',
          trigger: ['blur', 'change'],
        },
      ],
      password: [{ required: true, message: 'Tolong masukkan Sandi!' }],
      // ktp: [
      //   {
      //     validator: (_, value) => {
      //       if (fileList.value.length === 0) {
      //         return Promise.reject('File KTP harus diunggah')
      //       }
      //       return Promise.resolve()
      //     },
      //   },
      // ],
      // resep: [
      //   {
      //     validator: (_, value) => {
      //       if (fileListResep.value.length === 0) {
      //         return Promise.reject('File Resep harus diunggah')
      //       }
      //       return Promise.resolve()
      //     },
      //   },
      // ],
    })
    const registerForm = reactive({
      idTest: null,
      email: null,
      password: null,
      namaLengkap: null,
      gender: null,
      ttl: null,
      tempatLahir: null,
      nik: null,
      nomorAsuransi: null,
      jenisAsuransi: null,
      namaAsuransi: null,
      nomorHandphone: null,
      ktp: null,
      resep: null,
    })

    const changeAuthProvider = value => {
      store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    }
    const handleFinish = async values => {
      // console.log('masuk sini')
      validate(['namaLengkap', 'email', 'nomorHandphone', 'ktp', 'resep'])
        .then(() => {
          showModal()
        })
        .catch(err => {
          console.log(err)
        })
    }
    const handleFinishFailed = errors => {
      console.log(errors)
    }
    onMounted(() => {
      console.log(route.path)
      if (route.path === '/register') {
        isRegister.value = true
      }
    })

    const loadingModal = ref(false)
    const visible = ref(false)
    const showModal = () => {
      visible.value = true
    }
    const closeModal = () => {
      visible.value = false
    }
    const handleSubmit = async () => {
      const formData = new FormData()

      // Tambahkan data form (teks)
      formData.append('namaLengkap', registerForm.namaLengkap)
      formData.append('email', registerForm.email)
      formData.append('nomorHandphone', registerForm.nomorHandphone)

      // Tambahkan file KTP dan Resep ke FormData jika ada
      if (fileList.value.length > 0) {
        formData.append('ktp', fileList.value[0].originFileObj) // KTP file
      }

      if (fileListResep.value.length > 0) {
        formData.append('resep', fileListResep.value[0].originFileObj) // Resep file
      }

      // Kirim data menggunakan apiClient (Axios)
      try {
        const res = await apiClient.post('/users/register', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Pastikan ini ditetapkan
          },
        })
        if (res.status === 201) {
          console.log(res, 'inirespon')
          notification.success({
            message: res.data.message,
            description: 'Sukses melakukan pendaftaran',
            duration: 3,
          })

          // Redirect ke halaman sukses setelah 1 detik
          setTimeout(() => {
            router.push('/success')
          }, 1000)
        }
      } catch (error) {
        console.log(error, 'inierror')
      }
    }
    const handleOk = () => {
      loading.value = true

      //   setTimeout(() => {
      //     loading.value = false
      //     visible.value = false
      //   }, 2000)
    }

    const handleBack = () => {
      steps.value--
    }
    watch(
      [() => checkedConsent.value, () => route.path],
      ([newCheck], [oldCheck]) => {
        console.log('watch berubah')
        if (route.path === '/register') {
          isRegister.value = true
        } else {
          isRegister.value = false
        }
      },
      { deep: true },
    )
    const { resetFields, validate, validateInfos } = useForm(registerForm, rules)
    const fileList = ref([])
    const handleChange = ({ fileList: newFileList }) => {
      fileList.value = newFileList
      if (fileList.value.lengths === 0) {
        console.error('No file selected for upload.')
        return
      }
      console.log(fileList.value, 'inijumlahfilenya')
      // const file = fileList.value[0].originFileObj
      // console.log(file, 'iniadakah')
    }
    const fileListResep = ref([])
    const handleChangeResep = ({ fileList: newFileList }) => {
      fileListResep.value = newFileList
      if (fileListResep.value.lengths === 0) {
        console.error('No file selected for upload.')
        return
      }

      const file = fileList.value[0].originFileObj
      console.log(file, 'iniadakah')
    }
    const previewVisible = ref(false)
    const previewImage = ref('')
    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }
    const handleCancel = () => {
      previewVisible.value = false
    }

    return {
      handlePreview,
      previewVisible,
      previewImage,
      fileListResep,
      handleChangeResep,
      fileList,
      handleChange,
      closeModal,
      loadingModal,
      visible,
      showModal,
      handleOk,
      handleCancel,
      steps,
      isRegister,
      settings,
      loading,
      rules,
      registerForm,
      changeAuthProvider,
      handleFinish,
      handleFinishFailed,
      checked,
      checkedConsent,
      validateInfos,
      handleBack,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      handleSubmit,
    }
  },
}
</script>
<style lang="scss" module>
@import '@/components/Auth/style.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:global(.ant-checkbox-inner) {
  border-radius: 3px !important;
}

:global(.ant-steps-item-icon) {
  border-radius: 3px !important;
}

.custom-date-picker > div > input {
  height: 40px;
}
</style>
